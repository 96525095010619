export default {
  items: [
    {
      name: "Invoice Tools",
      url: "/collapse-default",
      icon: "fa fa-tools",
      children: [
        {
          name: "Invoice Search",
          url: "/accountant",
          icon: "fa fa-search",
        },
        {
          name: "Master Invoice",
          url: "/accountant/master-invoice",
          icon: "fa fa-file-chart-line",
        },
        {
          name: "Investigation Table",
          icon: "fa fa-table",
          url: "/accountant/investigation-table",
        },
        {
          name: "Service Estimates",
          url: "/accountant/service-estimates",
          icon: "fa fa-edit",
        },
      ],
    },
    {
      name: "Vendor Tools",
      url: "/collapse-default",
      icon: "fas fa-toolbox",
      children: [
        // {
        //   name: 'Generate Invoices',
        //   url: '/accountant/vendor-invoices',
        //   icon: 'fas fa-file-invoice',
        // },
        {
          name: "View Invoices",
          url: "/accountant/view-invoices",
          icon: "fas fa-list",
        },
        // {
        //   name: 'Create Vendor',
        //   icon: 'fas fa-inventory',
        //   url: '/accountant/create-vendor',
        // }
      ],
    },
    {
      name: "Project Management",
      icon: "fa fa-user",
      url: "/collapse-default",
      children: [
        // {
        //   name: "Project Managers",
        //   url: "/accountant/pms",
        //   icon: "fa fa-user",
        // },
        {
          name: "Afe Projects",
          url: "/accountant/projects",
          icon: "fa fa-layer-group",
        },
        {
          name: "Operations",
          url: "/accountant/resources",
          icon: "fa fa-folder-open",
        },
        {
          name: "Cost Center",
          icon: "fa fa-container-storage",
          url: "/accountant/cost-center",
        },
      ],
    },
    {
      name: "Authorities",
      icon: "fa fa-cog",
      url: "/collapse-default",
      children: [
        {
          name: "Budget Table",
          url: "/accountant/budget",
          icon: "fa fa-dollar-sign",
        },
        {
          name: "Authority Table",
          url: "/accountant/authority",
          icon: "fa fa-sort",
        },
      ],
    },
    {
      name: "Cost Code Buckets",
      url: "/accountant/buckets",
      icon: "fa fa-fill",
    },
    {
      name: "Charts",
      url: "/collapse-default",
      icon: "fa fa-chart-line",
      children: [
        {
          name: "Budget VS Actuals",
          url: "/accountant/chart-1",
          icon: "fa fa-chart-bar",
        },
        {
          name: "Actual Well Cost",
          url: "/accountant/chart-2",
          icon: "fa fa-chart-bar",
        },
        {
          name: "Actual Project Cost",
          url: "/accountant/chart-3",
          icon: "fa fa-chart-bar",
        },
        {
          name: "Invoice Chart",
          url: "/accountant/invoice-chart",
          icon: "fa fa-chart-bar",
        },
        {
          name: "Bucket Chart",
          url: "/accountant/chart-buckets",
          icon: "fa fa-chart-bar",
        },
      ],
    },
    {
      name: "Vendor Management",
      url: "/collapse-default",
      icon: "fa fa-chart-line",
      children: [
        {
          name: "Vendor",
          url: "/accountant/all-vendor",
          icon: "fa fa-cog",
        },
        {
          name: "Invite Vendor",
          url: "/accountant/invite",
          icon: "fa fa-cog",
        },
      ],
    },
    {
      name: "Qbyte FM Connector",
      url: "/collapse-default",
      icon: "fa fa-chart-line",
      children: [
        {
          name: "Parameters",
          url: "/accountant/parameters",
          icon: "fa fa-cog",
        },
        {
          name: "Invoice Configuration",
          url: "/accountant/configuration",
          icon: "fa fa-cog",
        },
      ],
    },
    {
      name: "All Users",
      url: "/accountant/all_users",
      icon: "fa fa-users",
      children: [
        {
          name: "KEC vendor users",
          url: "/accountant/all_vendors",
          icon: "fa fa-users",
        }
        ,{
          name: "KEC team users",
          url: "/accountant/all_users",
          icon: "fa fa-users",
        }
      ]
    },
  ],
};
